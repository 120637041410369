body {
  margin: 0;
  font-family: 'Montserrat-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat-Regular';
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./assets/Fonts/Montserrat-Regular.ttf) format('truetype')
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./assets/Fonts/Montserrat-Regular.ttf) format('truetype')
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./assets/Fonts/Montserrat-Regular.ttf) format('truetype')
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./assets/Fonts/Montserrat-Regular.ttf) format('truetype')
}