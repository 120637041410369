.svgicon  {
cursor: pointer;
fill: #d0dbe5 !important;
}
.svgicon:hover{
fill: #0084AD !important;
/* stroke:#0084AD !important; */

}
.svgiconblock  {
    cursor: pointer;
    fill: #d0dbe5 !important;
    }
.svgiconblock:hover{
    fill: #0084AD !important;
stroke:#0084AD !important;
}
.disableicon {
    cursor: pointer;
    fill: #d0dbe5 !important;
}
.disableicon:hover{
    cursor: pointer;
    fill: red !important;
}
.translate {
    fill:red !important;
    stroke:#d0dbe5;
}
.translate:hover{
    stroke:#0084AD;
}
.garbage{
fill:#d0dbe5  !important;
cursor:pointer
}
.garbage:hover{
    fill: #0084AD !important;
}